:root {
  --q-columns: 1fr;
  --q-row: repeat(2, auto);
  --progress-height: 20px;
}

.intro {
  &-container {
    display: grid;
    align-content: center;
    height: 100dvh;
    justify-content: center;
    justify-items: center;
    &#sent {
      transition: 0.4s ease-in;
      opacity: 0;
      //   align-content: start;

      .question {
        // padding-bottom: clamp(10px, 8vw, 80px);
      }
    }
    .question {
      display: grid;
      align-items: center;
      row-gap: 20px;
      grid-template-columns: var(--q-columns);
      grid-template-rows: var(--q-row);
      font-weight: 300;
      justify-items: center;
      animation: fade-in 2s;

      div {
        font-size: clamp(28px, 4.5vw, 50px);
        text-align: center;

        strong {
          font-weight: 500;
        }
      }
      span {
        font-size: clamp(24px, 3vw, 42px);
        border: 2px solid transparent;
        background-color: var(--brown);
        padding: 6px clamp(10px, 1vw, 15px);
        border-radius: clamp(8px, 1vw, 12px);
        cursor: pointer;
        margin-left: clamp(10px, 3vw, 35px);
        transition: 0.3s ease;
        user-select: none;

        &:hover {
          background-color: transparent;
          border-color: var(--text-color);
          color: var(--gold);
        }
        &:active {
          transform: scale(0.94);
        }
      }
    }
  }
}
.demo {
  &-container {
    display: grid;
    row-gap: 5px;
    font-weight: 300;
    max-width: 500px;
    overflow: hidden;
    animation: steps 4s;
    animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    animation-delay: 0.8s;
    text-align: center;
  }
  //   &-content {
  //     &#last {
  //       color: var(--gold);
  //     }
  //   }
  &-progress {
    display: grid;
    height: var(--progress-height);
    width: 80vw;
    max-width: clamp(200px, 40vw, 350px);
    border-radius: 10rem;
    overflow: hidden;

    span {
      height: 100%;
      background-color: var(--brown);
      animation: load 3s;
      animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes load {
  from {
    width: 0;
  }
  20% {
    width: 0;
  }
  to {
    width: 100%;
  }
}

// @keyframes steps {
//   1% {
//     height: 0;
//   }
//   4% {
//     height: 20px;
//   }
//   21% {
//     height: 20px;
//   }
//   28% {
//     height: 50px;
//   }
//   51% {
//     height: 50px;
//   }
//   58% {
//     height: 70px;
//   }
//   61% {
//     height: 70px;
//   }
//   68% {
//     height: 100px;
//   }
//   71% {
//     height: 100px;
//   }
//   78% {
//     height: 120px;
//   }
//   95% {
//     opacity: 1;
//   }
//   100% {
//     height: 120px;
//     opacity: 0;
//     visibility: hidden;
//   }
// }

@media screen and (min-width: 420px) {
  :root {
    --q-columns: repeat(2, auto);
    --q-row: auto;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --progress-height: 40px;
  }
}
