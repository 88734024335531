@import "../../layout/_mixins";

:root {
  --windows-rows: auto;
  --windows-columns: repeat(2, 450px);
}

.experience {
  &-container {
    display: grid;
    min-height: 650px;
    align-items: center;
    justify-content: center;
    padding: calc(var(--nav-height) + 30px) 5vw 80px;
    background-color: var(--bg-color);
    // text-align: var(--text-align);

    &.inView {
      --animation-name: slideX;
    }
  }

  &-head {
    padding-bottom: 40px;
    font-size: clamp(24px, 5vw, 36px);
    color: #ffffff;
    font-weight: 600;
    position: relative;
    justify-self: center;
    > span {
      color: var(--gold);
    }

    &::after {
      @include slideXAnimation(0.4s);
    }
  }
  &-window {
    &-container {
      display: grid;
      column-gap: 40px;
      row-gap: 70px;
      grid-template-rows: var(--windows-rows);
      grid-template-columns: var(--windows-columns);
    }
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: 15px;
    position: relative;

    &:first-child {
      overflow: hidden;
      height: 100%;
      &::after {
        @include slideXAnimation(0.2s);
        height: 110%;
      }
    }
    &:last-child {
      overflow: hidden;
      height: 100%;
      &::after {
        @include slideXAnimation(0.9s);
        height: 110%;
      }
    }

    &-head {
      font-size: clamp(20px, 2vw, 22px);
      padding-left: 35px;
    }

    &-content {
      display: grid;
      grid-template-columns: 20px 1fr;
      column-gap: 15px;
      //   background-color: var(--brown);
    }
    &-bar {
      background-color: var(--gold);
      width: 2px;
      height: auto;
      margin-top: -3px;
      display: grid;
      justify-self: center;
      grid-template-rows: repeat(3, 1fr);
      border-radius: 10rem;
      row-gap: 30px;

      span {
        background-color: var(--gold);
        width: 15px;
        height: 15px;
        margin-left: -6.5px;
        border-radius: 50%;
      }
    }
    &-body {
      display: grid;
      grid-template-rows: repeat(auto-fit, 1fr);
      align-content: start;
      // row-gap: 20px;
      z-index: 1;

      > div {
        margin-bottom: 30px;
      }
      > :last-child {
        margin-bottom: 5px !important;
      }
    }
  }
  &-tab {
    border: 2px solid var(--gold);
    border-radius: 6px;
    display: grid;
    grid-template-rows: 20px auto 1fr;
    row-gap: 9px;
    // align-content: space-between;
    font-weight: 300;
    padding: 10px;
    position: relative;
    background-color: transparent;
    height: 150px;
    // min-height: 130px;
    // max-height: 150px;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab-animation {
      position: absolute;
      height: 100%;
      z-index: -1;
      background: var(--brown);
      opacity: 0.1;
      width: 0;
      transition: 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      right: 0;
      transition-delay: 0s;
    }

    &:hover .tab-animation {
      width: 100%;
      left: 0;
      transition-delay: 0.3s !important;
    }

    &-date {
      color: var(--gold);
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: start;
      align-items: center;
      align-content: center;
      font-size: clamp(14px, 1vw, 16px);
      i {
        font-size: clamp(12px, 1vw, 14px);
        padding-right: 10px;
      }
    }
    &-title {
      font-weight: 400;
      font-size: 18px;
      color: #fff;
    }
    &-body {
      font-size: clamp(12px, 2.7vw, 14px);
      line-height: 1.4;
      opacity: 0.9;
    }
    &-link {
      font-size: clamp(12px, 2.7vw, 14px);
      line-height: 1.5;

      a {
        text-decoration: none;
        color: var(--gold);
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --windows-columns: clamp(0px, 88vw, 500px);
    --windows-rows: repeat(2, auto);
  }
}
