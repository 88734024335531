@import url("https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap");

body {
  margin: 0;
  font-family: "Signika", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::-webkit-scrollbar {
    display: none;
  }
}
