@mixin slideXAnimation($delay) {
  content: "";
  animation: var(--animation-name) 0.8s linear $delay forwards;
  position: absolute;
  width: 103%;
  height: 103%;
  background-color: var(--bg-color);
  right: -1.5%;
  top: -1%;
  z-index: 100;

  @media (prefers-reduced-motion: reduced) {
    animation-duration: 0s !important;
    animation-delay: 0s !important;
  }
}
