@import "../../layout/_mixins";

:root {
  --footer-columns: repeat(2, auto);
  --footer-rows: 1fr;
  --footer-bottom: 10px;
}

.footer {
  &-container {
    padding: 15px 5vw;
    padding-bottom: var(--footer-bottom);
    background-color: var(--about-bg);
    display: grid;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    column-gap: 35px;
    row-gap: 20px;
    grid-template-columns: var(--footer-columns);
    grid-template-rows: var(--footer-rows);

    &.inView {
      --animation-name: slideX;
    }

    > :last-child {
      display: grid;
      position: relative;

      &::after {
        @include slideXAnimation(0.8s);
        animation-duration: 0.4s;
        background-color: var(--about-bg);
      }
    }
  }
  &-copy {
    font-size: clamp(14px, 2.9vw, 18px);
    position: relative;
    text-align: center;
    line-height: 1.7;

    &::after {
      @include slideXAnimation(0.2s);
      background-color: var(--about-bg);
    }
  }

  &-btt {
    border: 2px solid var(--text-color);
    padding: 10px 15px;
    border-radius: 0.5rem;
    color: var(--text-color);
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 0;
      height: 110%;
      background-color: var(--text-color);
      top: 0;
      right: 0;
      left: inherit;
      z-index: -1;
      transition: inherit;
    }

    &:hover {
      &::after {
        width: 110%;
        left: 0;
        right: inherit;
      }
      color: var(--about-bg);
    }
    &:active {
      transition: inherit;
    }
  }
}

@media screen and (max-width: 720px) {
  :root {
    --footer-rows: 40px auto;
    --footer-columns: 1fr;
    --footer-bottom: 23px;
  }
}
