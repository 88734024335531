:root {
  --nav-height: 80px;
}

.nav {
  &-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300 !important;
    background-color: var(--bg-color-trans);
    backdrop-filter: blur(10px);
    width: 90vw;
    height: var(--nav-height);
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    padding: 7px 5vw 0;
    align-items: center;
    align-content: center;

    .name {
      font-size: clamp(20px, 3.5vw, 32px);
      font-weight: 500;
      pointer-events: none;
      padding-bottom: 7px;
      position: relative;
      &::after {
        content: "";
        animation: slideX 0.8s 0.1s linear forwards;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--bg-color);
        right: 0;
      }
    }
  }
  &-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    column-gap: 4vw;

    &-fixed {
      position: fixed;
      z-index: 250 !important;
      left: 0;
      display: grid;
      grid-template-columns: repeat(5, auto);
      justify-content: space-around;
      column-gap: 20px;
      align-items: last baseline;
      align-content: last baseline;
      width: calc(100vw - 24px);
      padding: 0 12px;
      top: calc(var(--nav-height) - 40px);
      transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
      overflow: hidden;
      background-color: var(--bg-color);
      overflow: hidden;

      &.show-menu {
        height: 30px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        top: calc(var(--nav-height) - 10px);
        visibility: visible;
        padding-bottom: 10px;
        padding-top: 20px;
        &::after {
          content: "";
          width: 2000vw;
          left: 0;
          height: 0;
          background-color: var(--brown);
          position: absolute;
          animation: menuEffect 0.4s linear forwards;
        }
      }
      &.hide-menu {
        height: 0 !important;
        // opacity: 0.8;
        // visibility: hidden;
        transition: 0.3s ease-in !important;
        // &::before {
        //   top: var(--nav-height);
        //   content: "";
        //   width: 100vw;
        //   height: 50px;
        //   background-color: var(--brown);
        //   position: absolute;
        //   z-index: 1;
        //   animation: menuEffect 0.65s linear 0.2s reverse;
        // }
      }
    }
  }
}

@keyframes menuEffect {
  0% {
    top: 0;
    height: 50px;
  }
  50% {
    top: 0;
    height: 50px;
  }
  100% {
    top: 60px;
    height: 0px;
  }
}

@keyframes slideX {
  to {
    width: 0;
  }
}

.menu {
  &-items {
    font-size: clamp(14px, 3vw, 18px);
    cursor: pointer;
    padding-bottom: 6.5px;
    position: relative;
    &:last-child::before {
      animation: slideX 1s 0.3s linear forwards;
    }
    &:nth-child(4)::before {
      animation: slideX 0.8s 0.3s linear forwards;
    }
    &::before {
      content: "";
      animation: slideX 0.5s 0.3s linear forwards;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--bg-color);
      right: 0;
      transition: 3s;
    }

    &::after {
      transition: 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      content: "";
      width: 0;
      height: 3px;
      position: absolute;
      bottom: -1px;
      right: 0;
      left: inherit;
      background-color: #fff;
      border-radius: 10rem;
    }
    &:hover::after {
      width: 100%;
      right: inherit;
      left: 0;
    }
  }
  &-active {
    color: var(--gold);
    &::after {
      background-color: var(--gold);
    }
  }
  &-bar {
    display: grid;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: #ffffff00;
    width: 30px;
    justify-items: center;
    justify-content: center;
    align-items: center;
    transition: 0.5s ease;
    span {
      transition: 0.5s ease;
      // font-weight: 100;
      // &:active {
      //   transform: rotateY(120deg);
      // }
      font-size: 24px;
      &:hover {
        // transform: scale(1.2);
      }
      &:active {
        transform: rotateY(-90deg);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  :root {
    --nav-height: 65px;
  }
}
