@import "../../layout/_mixins";

:root {
  --text-align: left;
  --overlay-display: grid;
  --overlay-index: 0;
  --image-opacity: 1;
  --image-right: clamp(5vw, 200px, 15vw);
  --content-max-width: 650px;
  --image-animation-name: none;
  --image-top: clamp(170px, calc(77px + 5vw), 50vw);
}

.home {
  &-container {
    padding: calc(var(--nav-height) + 10px) 5vw;
    animation: move-alt alternate forwards;
    justify-self: center;
    width: 90vw;
    max-width: 1240px;
    display: grid;
    overflow: hidden;

    &.inView {
      --label-animation: blinks;
      --animation-name: slideX;
    }
  }
  &-content {
    z-index: calc(var(--overlay-index) + 100);
    max-width: var(--content-max-width);
    display: grid;
    height: calc(100vh - 150px);
    min-height: 550px;
    max-height: 650px;
    align-items: center;
    align-content: center;
    justify-items: var(--text-align);
    position: relative;
    // display: none;
    &-button {
      margin-top: 30px;
      height: 43px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: var(--text-align);
      column-gap: 30px;
      position: relative;

      &::after {
        @include slideXAnimation(0.9s);
        z-index: 100 !important;
      }

      div {
        display: grid;
        align-content: center;
        padding: 0 24px;
        border-radius: 6px;
        border: 2px solid var(--gold);
        position: relative;
        cursor: pointer;
        &::before {
          align-self: center;
          justify-self: start;
          z-index: 2;
          color: var(--bg-color);
          transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
          font-weight: 500;
        }
        &::after {
          transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
          content: "";
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;
          background-color: var(--gold);
        }
      }

      :first-child {
        &::before {
          content: "Hire Me";
          width: 100%;
        }
        &::after {
          left: 0;
          background-color: var(--gold);
        }
      }
      :last-child {
        &::before {
          content: "Let's Talk";
          width: 100%;
          color: var(--text-color);
        }
        &::after {
          left: 0;
          width: 0;
        }
      }
    }
    &-social {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(4, auto);
      column-gap: 20px;
      position: relative;

      &::after {
        @include slideXAnimation(1.5s);
        z-index: 100 !important;
      }

      div {
        padding: 18px;
        border: 2px solid var(--gold);
        display: grid;
        align-content: center;
        justify-content: center;
        border-radius: 50%;
        aspect-ratio: 1;
        position: relative;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        overflow: hidden;

        i {
          margin-top: 1px;
          font-size: clamp(18px, 1.3vw, 22px);
          color: var(--bg-color);
          position: absolute;
          z-index: 2;
          color: var(--gold);
          align-self: center;
          justify-self: center;
          transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        }

        span {
          transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
          position: absolute;
          z-index: 1;
          width: 0;
          height: 100%;
          background-color: var(--gold);
          right: 0;
          left: inherit;
        }

        &:hover > span {
          width: 100%;
          right: inherit;
          left: 0;
        }
        &:hover > i {
          color: var(--bg-color);
        }
      }
    }
    &-name {
      font-size: clamp(28px, 6vw, 52px);
      font-weight: 600;
      position: relative;

      &::after {
        @include slideXAnimation(0.1s);
      }
    }

    &-label {
      display: grid;
      padding-top: 10px;
      font-family: sans-serif;
      font-size: clamp(18px, 3.8vw, 26px);
      color: var(--bg-color);
      -webkit-text-stroke: 0.7px var(--gold);
      text-stroke: 0.7px var(--gold);
      -webkit-background-clip: text;
      background-clip: text;
      position: relative;
      opacity: 0;
      animation: var(--label-animation) 1s linear 0.5s forwards;

      &::after {
        content: "Frontend Developer";
        color: var(--gold);
        position: absolute;
        width: 0%;
        height: 80%;
        justify-self: start;
        padding-top: 10px;
        white-space: nowrap;
        overflow: hidden;
        animation: write 5s linear 2s infinite forwards;
      }

      &::before {
        content: "";
        margin-top: 9px;
        position: absolute;
        z-index: 1;
        border-right: 1px solid var(--gold);
        height: 70%;
        width: 0%;
        padding-right: 1px;
        animation: write 5s linear 2s infinite forwards;
      }
    }
    &-text {
      position: relative;
      font-size: clamp(16px, 2vw, 20px);
      font-weight: 300;
      padding-top: 20px;
      line-height: 1.4;
      text-align: var(--text-align);
      max-width: 600px;

      &::after {
        @include slideXAnimation(0.7s);
      }
    }
  }
  &-image {
    position: absolute;
    z-index: -1;
    opacity: var(--image-opacity);
    right: var(--image-right);
    top: var(--image-top);
    justify-self: end;
    padding-right: clamp(0px, 3vw, 30px);

    animation: var(--image-animation-name) forwards;
    -moz-animation: none !important; /* Firefox */
    -o-animation: var(--image-animation-name) forwards; /* Opera */
    -webkit-animation: var(--image-animation-name) forwards; /* Safari and Chrome */
    animation-timeline: scroll(block) !important;
    -o-animation-timeline: scroll(block) !important; /* Opera */
    -webkit-animation-timeline: scroll(
      block
    ) !important; /* Safari and Chrome */
    user-select: none;

    &::after {
      @include slideXAnimation(1.2s);
    }
    img {
      width: clamp(250px, 60vw, 450px);
      height: clamp(200px, 70vw, 500px);
      object-fit: cover;
      object-position: 100% 0;
      overflow: hidden;
      border-bottom-right-radius: 100%;
      border-bottom-left-radius: 100%;
    }
  }
}

.overlay {
  animation: overlay alternate forwards;
  -moz-animation: none !important; /* Firefox */
  -webkit-animation: overlay alternate forwards; /* Safari and Chrome */
  -o-animation: overlay alternate forwards; /* Opera */
  animation-timeline: scroll(block nearest) !important;
  -moz-animation-timeline: scroll(block nearest) !important; /* Firefox */
  -webkit-animation-timeline: scroll(
    block nearest
  ) !important; /* Safari and Chrome */
  -o-animation-timeline: scroll(block nearest) !important; /* Opera */
  position: absolute;

  z-index: var(--overlay-index);
  width: clamp(280px, 65vw, 500px);
  height: clamp(200px, 70vw, 500px);
  right: var(--image-right);
  justify-self: end;
  padding-right: 30px;
  top: var(--image-top);
  display: var(--overlay-display);
  background: radial-gradient(
    circle at 55% 15%,
    #1b1a1710,
    #1b1a1742,
    #1b1a17ad,
    var(--bg-color),
    var(--bg-color)
  );
  &::after {
    transition: 1s ease all;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: radial-gradient(
      circle at 55% 15%,
      #1b1a1750,
      #1b1a17a0,
      #1b1a17b9,
      var(--bg-color),
      var(--bg-color)
    );
  }

  &:hover::after {
    opacity: 0;
  }
}
@keyframes write {
  0% {
    width: 0;
  }
  60% {
    width: 102%;
  }
  75% {
    width: 0;
  }
}

@keyframes slideX {
  to {
    width: 0 !important;
    visibility: hidden;
  }
}

@keyframes blinks {
  to {
    opacity: 1;
  }
}

// @keyframes move {
//   10% {
//     opacity: 0;
//     display: none;
//   }
//   to {
//     opacity: 0;
//     transform: scale(5) translateY(calc(var(--nav-height) * 1.1));
//   }
// }

@keyframes overlay {
  10% {
  }
  2% {
    // opacity: 0;
  }
  3% {
    // transform: scale(0);
    // z-index: -1 !important;
  }
  4% {
    // opacity: 0;
  }
  100% {
    // opacity: 0;
    // transform: scale(0);
    transform: scale(5) translateY(calc(var(--nav-height) * 1.1));
  }
}
@keyframes move {
  0% {
    z-index: -1 !important;
  }
  15% {
    opacity: 0;
  }
  to {
    opacity: 0;
    transform: scale(3) translateY(calc(var(--nav-height) * 1.1));
  }
}

@keyframes move-alt {
  0% {
    background: linear-gradient(to top, #1b1a17, #1b1a1740, transparent);
    // background: #1b1a17e6;
  }
  1% {
    background: linear-gradient(to top, #1b1a17, #1b1a1785, transparent);
  }
  100% {
    background: linear-gradient(to top, #1b1a17, #1b1a1790, transparent);
  }
}

@media screen and (max-width: 920px) {
  :root {
    --text-align: center;
    --content-max-width: auto;
    --image-top: calc(77px + 5vw);
  }
}

@media screen and (max-width: 1200px) {
  :root {
    --overlay-display: none;
    --overlay-index: -1;
    --image-opacity: 0.15;
  }
}

@media screen and (min-width: 920px) {
  :root {
    --image-right: 0;
  }
}
@media screen and (min-width: 1024px) {
  :root {
    --image-right: inherit;
  }
  .home-container {
    &.inView {
      --image-animation-name: move;
    }
  }
}

@media screen and (min-width: 600px) {
  .home-content-button {
    :first-child {
      &:hover::before {
        color: var(--text-color);
      }
      &:hover::after {
        right: 0;
        width: 0%;
      }
    }
    :last-child {
      &:hover::before {
        color: var(--bg-color);
      }
      &:hover::after {
        left: 0;
        width: 100%;
      }
    }
  }
}
