@import "../../layout/_mixins";

:root {
  --about-bg: #32200c;
}

.about {
  &-container {
    z-index: 10;
    padding: 3vw 5vw 30px;
    height: 700px;
    display: grid;
    grid-template-rows: repeat(4, auto);
    justify-items: center;
    align-content: center;
    text-align: center;
    color: #ffffff;
    background-color: var(--about-bg);

    &.inView {
      --animation-name: slideX;
    }
  }
  &-head {
    padding-bottom: clamp(50px, 5vw, 70px);
    font-size: clamp(21px, 5vw, 36px);
    color: #ffffff;
    font-weight: 600;
    position: relative;
    > span {
      color: var(--gold);
    }

    &::after {
      @include slideXAnimation(0.1s);
      background-color: var(--about-bg);
    }
  }
  &-image {
    width: clamp(120px, 20vw, 200px);
    height: clamp(120px, 20vw, 200px);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: -0.3vw clamp(12px, 1.2vw, 20px);
      border-radius: 50%;
      border: 2px solid var(--gold);
      background-color: var(--bg-color);
    }
    position: relative;
    &::after {
      content: "";
      position: absolute;
      padding: 12px;
      width: 100%;
      max-width: 100vw;
      height: 100%;
      border: 2px solid transparent;
      border-bottom-color: var(--gold);
      border-top-color: var(--gold);
      left: -12px;
      top: -12px;
      border-radius: 50%;
      animation: rotate 3s infinite linear;
    }
    &:hover::after {
      animation-play-state: paused;
    }
    &::before {
      @include slideXAnimation(0.1s);
      background-color: var(--about-bg);
      z-index: 100;
      width: 160%;
      top: -20px;
      height: 130%;
      max-height: 250px;
      right: -30px;
    }
  }
  &-title {
    margin-top: 50px;
    font-size: clamp(18px, 4vw, 28px);
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;

    &::after {
      @include slideXAnimation(0.5s);
      background-color: var(--about-bg);
    }
  }
  &-content {
    padding-top: 20px;
    width: clamp(120px, 85vw, 820px);
    font-size: clamp(14px, 2.5vw, 18px);
    font-weight: 320;
    line-height: 1.5;
    position: relative;
    span {
      display: inline-block;
      padding-left: 5px;
    }
    &::after {
      @include slideXAnimation(0.8s);
      background-color: var(--about-bg);
    }
  }
}

@keyframes slideX {
  to {
    width: 0 !important;
    visibility: hidden;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
