:root {
  --bg-color: #1b1a17;
  --bg-color-trans: #1b1a1790;
  --text-color: #f0e3ca;
  --gold: #ff8303;
  --brown: #a35709;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  -webkit-tap-highlight-color: #ffffff00;
  overflow: hidden;
}
.container {
  max-height: 100dvh;
  max-width: 100vw;
  overflow-x: hidden;
  display: grid;
  // scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  position: relative;

  > div {
    // scroll-snap-align: start;
    // scroll-snap-stop: always;
    z-index: 1;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
